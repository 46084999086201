import { Col, Form, Input, Modal, Row } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createProuct } from '~/redux/products/actions';

type ProductAddModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
}

const ProductAddModal = ({ isModalOpen, setIsModalOpen }: ProductAddModalProps): JSX.Element => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [databasePrefix, setDatabasePrefix] = useState('');

  const handleOk = () => {
    dispatch(createProuct({ name, code, databasePrefix }));
    setIsModalOpen(false);
    removeInputs();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    removeInputs();
  };

  const removeInputs = () => {
    setName('');
    setCode('');
    setDatabasePrefix('');
  }

  const isFormValid = name && code && databasePrefix;

  return (
    <Modal
      title="Create a new Product"
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ disabled: !isFormValid }}
    >
      <Form layout="vertical">
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="Name">
              <Input
                required
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Code">
              <Input
                required
                placeholder="Code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Database Prefix">
              <Input
                required
                placeholder="Database Prefix"
                value={databasePrefix}
                onChange={(e) => setDatabasePrefix(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ProductAddModal;