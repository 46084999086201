import tenants from './tenants/sagas'
import tenantDetails from './tenants/details/sagas'
import users from './users/sagas'
import userDetails from './users/details/sagas'
import products from './products/sagas'
import productInstances from './product-instance/sagas'
import productInstancesMembers from './product-instance-member/sagas'
import subscriptions from './subscriptions/sagas'

export default [tenants, tenantDetails, users, userDetails, products, productInstances, productInstancesMembers, subscriptions]
