/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { all, put, takeEvery, select } from 'redux-saga/effects'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/app/redux/interface'
import { AnyAction } from 'redux'
import { notification } from 'antd'
import { Tenant } from '@antegma/accallio-cloud-management-api'
import tenantApi from '~/services/management/tenantApi'
import actions from './actions'
import { ISagas } from '../interface'

/**
 *
 */
export function* GET_TENANTS({ payload }: AnyAction) {
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
  const options = { headers: { Authorization: `Bearer ${accessToken}` } }

  const { page, pageSize } = payload

  const response = yield tenantApi.getTenants(page, pageSize, options).catch((error) => {
    notification.error({
      message: 'Tenant: cannot load Tenants',
      description: error.message,
    })
  })

  yield put({
    type: actions.SET_STATE,
    payload: {
      tenants: response.data.items,
      page: response.data.page,
      pageSize: response.data.pageSize,
      total: response.data.total,
    },
  })
}

/**
 *
 */
export function* ADD_TENANT({ payload }: AnyAction) {
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
  const options = { headers: { Authorization: `Bearer ${accessToken}` } }

  const { tenant } = payload

  yield tenantApi.createTenant(tenant, options)

  const { tenantsState } = yield select((state: ISagas) => ({ tenantsState: state.tenants }))

  yield put({
    type: actions.GET_TENANTS,
    payload: {
      page: tenantsState.page,
      pageSize: tenantsState.pageSize,
    },
  })
}

/**
 *
 */
export function* EDIT_TENANT({ payload }: AnyAction) {
  const { tenant } = payload
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
  const options = { headers: { Authorization: `Bearer ${accessToken}` } }

  const tenantId = (tenant as Tenant).id as string

  yield tenantApi.updateTenant(tenantId, tenant, options)

  const { tenantsState } = yield select((state: ISagas) => ({ tenantsState: state.tenants }))

  yield put({
    type: actions.GET_TENANTS,
    payload: {
      page: tenantsState.page,
      pageSize: tenantsState.pageSize,
    },
  })
}

/**
 *
 */
export function* DELETE_TENANT({ payload }: AnyAction) {
  const { tenantId } = payload
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
  const options = { headers: { Authorization: `Bearer ${accessToken}` } }

  yield tenantApi.deleteTenant(tenantId, options).catch((error) => {
    notification.error({
      message: `Tenants: Unable to delete Tenant with ID ${tenantId}`,
      description: error.message,
    })
  })

  const { tenantsState } = yield select((state: ISagas) => ({ tenantsState: state.tenants }))

  yield put({
    type: actions.GET_TENANTS,
    payload: {
      page: tenantsState.page,
      pageSize: tenantsState.pageSize,
    },
  })
}

/**
 *
 */
export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_TENANTS, GET_TENANTS),
    takeEvery(actions.DELETE_TENANT, DELETE_TENANT),
    takeEvery(actions.ADD_TENANT, ADD_TENANT),
    takeEvery(actions.EDIT_TENANT, EDIT_TENANT),
  ])
}
