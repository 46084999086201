/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { all, put, takeEvery, select } from 'redux-saga/effects'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/app/redux/interface'
import { AnyAction } from 'redux'
import { notification } from 'antd'
import tenantApi from '~/services/management/tenantApi'
import actions from './actions'

/**
 *
 */
export function* GET_TENANT({ payload }: AnyAction) {
    const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
    const { tenantId } = payload

    yield put({
        type: actions.SET_STATE,
        payload: {
            loading: true,
        },
    })

    const options = { headers: { Authorization: `Bearer ${accessToken}` } }

    const response = yield tenantApi.getTenant(tenantId, options).catch((error) => {
        notification.error({
            message: 'Tenant: cannot load Tenants',
            description: error.message,
        })
    })

    yield put({
        type: actions.SET_STATE,
        payload: {
            loading: false,
            tenant: response.data,
        },
    })
}

export function* GET_TENANT_USERS({ payload }: AnyAction) {
    const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
    const options = { headers: { Authorization: `Bearer ${accessToken}` } }

    const { tenantId } = payload
    const response = yield tenantApi.getUsers1(tenantId, undefined, undefined, options).catch((error) => {
        notification.error({
            message: 'Tenant: Could not find users for this tenant',
            description: error.message,
        })
    })

    yield put({
        type: actions.SET_STATE,
        payload: {
            users: response.data.items,
        },
    })
}

export function* INVITE_USER({ payload }: AnyAction) {
    const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
    const options = { headers: { Authorization: `Bearer ${accessToken}` } }

    const { tenantId, user } = payload

    const response = yield tenantApi.inviteUser(tenantId, user, options).catch((error) => {
        notification.error({
            message: 'Tenant: Could not send an invitation',
            description: error.message,
        })
    })

    yield put({
        type: actions.SET_STATE,
        payload: {
            user: response.data,
        },
    })
}

/**
 *
 */
export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_TENANT, GET_TENANT),
        takeEvery(actions.INVITE_USER, INVITE_USER),
        takeEvery(actions.GET_TENANT_USERS, GET_TENANT_USERS),
    ]
    )
}
