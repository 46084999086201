import React from 'react'
import { Link } from 'react-router-dom'
import { PageHeader, Pagination, Table, Icon, Popconfirm, Tooltip, Button } from 'antd'
import { User } from '@antegma/accallio-cloud-management-api'

type UserTableProps = {
  usersState: {
    users: User[]
    page: number
    total: number
    pageSize: number
  }
}

const UserTable = ({ usersState }: UserTableProps): JSX.Element => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (text: string, record: User): JSX.Element => (
        <Link to={`users/${record.id}`}>
          {record.givenName} {record.familyName}
        </Link>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text: any): string => `${text}`,
    },
    {
      title: 'Invitation pending?',
      dataIndex: 'invitationPending',
      key: 'invitationPending',
      filters: [
        {
          text: 'is pending',
          value: '1',
        },
        {
          text: 'is not pending',
          value: '0',
        },
      ],
      sorter: true,
      render: (flag: boolean): JSX.Element | undefined => (flag ? <Icon type="check" /> : undefined),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_text: any, record: User): JSX.Element => {
        return (
          <>
            <Popconfirm
              cancelText="No"
              okText="Yes"
              onConfirm={() => console.log('delete user')}
              title="Are you sure delete this user?"
            >
              <Tooltip placement="left" title="Delete user">
                <Button type="link">
                  <Icon type="delete" />
                </Button>
              </Tooltip>
            </Popconfirm>
          </>
        )
      },
    },
  ]

  return (
    <PageHeader>
      <Table columns={columns} dataSource={usersState.users} pagination={false} />
      <Pagination defaultCurrent={usersState.page} total={usersState.total} pageSize={usersState.pageSize} />
    </PageHeader>
  )
}

export default UserTable;