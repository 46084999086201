import { AnyAction } from 'redux'

const actions = {
    SET_STATE: 'USERS/SET_STATE',
    GET_STATE: 'USERS/GET_STATE',
    GET_USERS: 'USERS/GET_USERS',
    DELETE_USER: 'USERS/DELETE_USER',
    SEARCH_USER: 'USERS/SEARCH_USER',
    ADD_USER: 'USERS/ADD_USER',
    EDIT_USER: 'USERS/EDIT_USER',
}
export default actions

export const getUsers = (page: number, pageSize: number): AnyAction => {
    return {
        type: actions.GET_USERS,
        payload: {
            page,
            pageSize,
        },
    }
}

export const deleteUser = (userId: string): AnyAction => {
    return {
        type: actions.DELETE_USER,
        payload: {
            userId,
        },
    }
}

export const searchUser = (value: string): AnyAction => {
    return {
        type: actions.SEARCH_USER,
        payload: {
            value,
        },
    }
}
