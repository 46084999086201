/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { all, put, takeEvery, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/app/redux/interface'
import { AnyAction } from 'redux'
import subscriptionApi from '~/services/management/subscriptionApi'
import actions from './actions'



export function* GET_SUBSCRIPTIONS({ payload }: AnyAction) {
    const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }));

    yield put({
        type: actions.SET_STATE,
        payload: {
            loading: true,
        },
    })

    const options = { headers: { Authorization: `Bearer ${accessToken}` } };
    const { page, pageSize, tenantId } = payload;

    const response = yield subscriptionApi.getSubscriptions(page, pageSize, tenantId, options).catch((error) => {
        notification.error({
            message: 'Subscriptions: cannot load Subscriptions',
            description: error.message,
        })
    })

    yield put({
        type: actions.SET_STATE,
        payload: {
            loading: false,
            subscriptions: response.data.items,
            page: response.data.page,
            total: response.data.total,
        },
    })
}


/**
 *
 */
export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_SUBSCRIPTIONS, GET_SUBSCRIPTIONS),
    ])
}
