/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { all, put, takeEvery, select } from 'redux-saga/effects'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/app/redux/interface'
import { AnyAction } from 'redux'
import { notification } from 'antd'
import userApi from '~/services/management/userApi'
import actions from './actions'

/**
 *
 */
export function* GET_USER({ payload }: AnyAction) {
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
  const { userId } = payload

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }

  const response = yield userApi.getUser(userId, options).catch((error) => {
    notification.error({
      message: 'User: cannot load Users',
      description: error.message,
    })
  })

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
      user: response.data,
    },
  })
}

/**
 *
 */
export default function* rootSaga() {
  yield all([takeEvery(actions.GET_USER, GET_USER)])
}
