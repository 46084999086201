import { Tenant } from '@antegma/accallio-cloud-management-api'
import { AnyAction } from 'redux'
import actions from './actions'

export type TTenantsState = {
  tenants: Tenant[]
  page: number
  pageSize: number
  total: number
  loading: boolean
}

const initialState = {
  tenants: [],
  page: 1,
  pageSize: 10,
  total: 0,
  loading: false,
}

/**
 * @param state
 * @param action
 */
export default function tenantsReducer(state: TTenantsState = initialState, action: AnyAction): TTenantsState {
  switch (action.type) {
    case actions.SET_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case actions.GET_STATE:
      return state
    default:
      return state
  }
}
