/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { all, put, takeEvery, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/app/redux/interface'
import { AnyAction } from 'redux'
import actions from './actions'
import productInstanceApi from '~/services/management/productInstanceApi'



export function* GET_PRODUCT_INSTANCES({ payload }: AnyAction) {
    const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }));

    yield put({
        type: actions.SET_STATE,
        payload: {
            loading: true,
        },
    })

    const options = { headers: { Authorization: `Bearer ${accessToken}` } };
    const { page, pageSize, tenentId } = payload;

    const response = yield productInstanceApi.getProductInstances(page, pageSize, tenentId, options).catch((error) => {
        notification.error({
            message: 'Products Instance: cannot load Product Instances',
            description: error.message,
        })
    })

    yield put({
        type: actions.SET_STATE,
        payload: {
            loading: false,
            productInstances: response.data.items,
            page: response.data.page,
            total: response.data.total,
        },
    })
}

export function* ADD_PRODUCT_INSTANCE({ payload }: AnyAction) {
    const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
    const options = { headers: { Authorization: `Bearer ${accessToken}` } }

    const { productInstance } = payload
    yield productInstanceApi.createProductInstance(productInstance, options);

    yield put({
        type: actions.GET_PRODUCT_INSTANCES,
        payload: {
            loading: true,
        },
    })
}

export function* DELETE_PRODUCT_INSTANCE({ payload }: AnyAction) {
    const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
    const options = { headers: { Authorization: `Bearer ${accessToken}` } }

    const { id } = payload
    yield productInstanceApi.deleteProductInstance(id, options);

    yield put({
        type: actions.GET_PRODUCT_INSTANCES,
        payload: {
            loading: true,
        },
    })
}

/**
 *
 */
export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_PRODUCT_INSTANCES, GET_PRODUCT_INSTANCES),
        takeEvery(actions.ADD_PRODUCT_INSTANCE, ADD_PRODUCT_INSTANCE),
        takeEvery(actions.DELETE_PRODUCT_INSTANCE, DELETE_PRODUCT_INSTANCE),
    ])
}
