import React from 'react'
import { PageHeader, Pagination, Table, Popconfirm, Tooltip, Button } from 'antd'
import { Subscription } from '@antegma/accallio-cloud-management-api'
import { DeleteOutlined } from '@ant-design/icons'


type ProductInstanceTableProps = {
    subscriptionsState: {
        subscriptions: Subscription[]
        page: number
        total: number
        pageSize: number
    }
}

const SubscriptionsTable = ({ subscriptionsState }: ProductInstanceTableProps): JSX.Element => {
    const columns = [
        {
            title: 'Payment Provider ID',
            dataIndex: 'paymentProviderId',
            key: 'paymentProviderId',
            sorter: true,
            render: (text: any): string => `${text}`,
        },
        {
            title: 'Payment Provider',
            dataIndex: 'paymentProviderType',
            key: 'paymentProviderType',
            render: (text: any): string => `${text}`,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text: any): string => `${text}`,
        },
        {
            title: 'Action',
            key: 'action',
            render: (item: any): JSX.Element => {
                return (
                    <Popconfirm
                        cancelText="No"
                        okText="Yes"
                        onConfirm={() => console.log(item.id)}
                        title="Are you sure you want to delete this subscription?"
                    >
                        <Tooltip placement="left" title="Delete subscription">
                            <Button type="link">
                                <DeleteOutlined />
                            </Button>
                        </Tooltip>
                    </Popconfirm>
                )
            },
        },
    ]

    return (
        <PageHeader>
            <Table columns={columns} dataSource={subscriptionsState.subscriptions} pagination={false} />
            <Pagination defaultCurrent={subscriptionsState.page} total={subscriptionsState.total} pageSize={subscriptionsState.pageSize} />
        </PageHeader>
    )
}

export default SubscriptionsTable;