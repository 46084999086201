import tenants from './tenants/reducers'
import tenantDetails from './tenants/details/reducers'
import users from './users/reducers'
import userDetails from './users/details/reducers'
import products from './products/reducers'
import productInstances from './product-instance/reducers'
import productInstanceMembers from './product-instance-member/reducers'
import subscriptions from './subscriptions/reducers'

export default {
    tenants,
    tenantDetails,
    users,
    userDetails,
    products,
    productInstances,
    productInstanceMembers,
    subscriptions
}
