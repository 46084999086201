/* eslint-disable @typescript-eslint/no-explicit-any */

import { useDispatch, useSelector } from "react-redux"
import { PlusCircleTwoTone } from "@ant-design/icons"
import Loader from "@antegma/accallio-cloud-react-components/lib/app/components/Loader"
import { Button, Card, Col, PageHeader, Row } from "antd"
import React, { useEffect, useState } from "react"
import ProductAddModal from "./components/ProductAddModal"
import ProductTable from "./components/ProductTable"
import { ISagas } from "~/redux/interface"
import { getProducts } from "~/redux/products/actions"


const Products = (): JSX.Element => {
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const productState = useSelector((state: ISagas) => { return state.products });

  useEffect(() => {
    dispatch(getProducts(productState.page, productState.pageSize));
  }, [productState.page, productState.pageSize]);

  return productState.loading !== true ? (
    <PageHeader title="Product">
      <Row gutter={16}>
        <Col span={24}>
          <Card
            title="Product Overview"
            extra={
              <Button icon={<PlusCircleTwoTone />} onClick={() => setIsModalOpen(true)}>
                Create a Product
              </Button>
            }
          >
            <ProductTable productState={productState} />
          </Card>
        </Col>
      </Row>
      <ProductAddModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </PageHeader>
  ) : (
    <Loader />
  )
}


export default {
  name: 'Products',
  routeProps: {
    path: '/products',
    component: Products,
  },
  navProps: {
    title: 'Products',
  },
  children: [],
}
