import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { PageHeader, Pagination, Table, Icon, Popconfirm, Tooltip, Button } from 'antd'
import Loader from '@antegma/accallio-cloud-react-components/lib/app/components/Loader'
import { User } from '@antegma/accallio-cloud-management-api'
import { ISagas } from '~/redux/interface'
import { deleteUser, getUsers } from '~/redux/users/actions'
import UserDetails from './details'

const Users = (): JSX.Element => {
  const dispatch = useDispatch()

  const usersState = useSelector((state: ISagas) => {
    return state.users
  })

  const handleDeleteUser = (user: User): void => {
    if (user.id !== undefined) {
      dispatch(deleteUser(user.id))
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (text: string, record: User): JSX.Element => (
        <Link to={`users/${record.id}`}>
          {record.givenName} {record.familyName}
        </Link>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text): string => `${text}`,
    },
    {
      title: 'Invitation pending?',
      dataIndex: 'invitationPending',
      key: 'invitationPending',
      filters: [
        {
          text: 'is pending',
          value: '1',
        },
        {
          text: 'is not pending',
          value: '0',
        },
      ],
      sorter: true,
      render: (flag: boolean): JSX.Element | undefined => (flag ? <Icon type="check" /> : undefined),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_text, record: User): JSX.Element => {
        return (
          <>
            <Popconfirm
              cancelText="No"
              okText="Yes"
              onConfirm={(): void => {
                handleDeleteUser(record)
              }}
              title="Are you sure delete this user?"
            >
              <Tooltip placement="left" title="Delete user">
                <Button type="link">
                  <Icon type="delete" />
                </Button>
              </Tooltip>
            </Popconfirm>
          </>
        )
      },
    },
  ]

  useEffect(() => {
    dispatch(getUsers(usersState.page, usersState.pageSize))
  }, [])

  const onChange = (page: number, pageSize?: number): void => {
    dispatch(getUsers(page, pageSize !== undefined ? pageSize : usersState.pageSize))
  }

  return usersState.loading !== true ? (
    <PageHeader title="Users">
      <Table columns={columns} dataSource={usersState.users} pagination={false} />
      <Pagination defaultCurrent={usersState.page} total={usersState.total} pageSize={usersState.pageSize} onChange={onChange} />
    </PageHeader>
  ) : (
    <Loader />
  )
}

export default {
  name: 'Users',
  routeProps: {
    path: '/users',
    component: Users,
  },
  navProps: {
    title: 'Users',
  },
  children: [UserDetails],
}
