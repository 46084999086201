import { Subscription } from '@antegma/accallio-cloud-management-api'
import { AnyAction } from 'redux'
import actions from './actions'

export type TSubscriptionState = {
    subscriptions: Subscription[]
    page: number
    pageSize: number
    total: number
    loading: boolean
}

const initialState = {
    subscriptions: [],
    page: 1,
    pageSize: 10,
    total: 0,
    loading: false,
}

/**
 * @param state
 * @param action
 */
export default function subscriptionReducer(state: TSubscriptionState = initialState, action: AnyAction): TSubscriptionState {
    switch (action.type) {
        case actions.SET_STATE:
            return {
                ...state,
                ...action.payload,
            }
        case actions.GET_STATE:
            return state
        case actions.CHANGE_PAGINATION:
            return {
                ...state,
                page: action.payload.page,
                pageSize: action.payload.pageSize,
            };
        default:
            return state
    }
}
