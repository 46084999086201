/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { Card, Col, Descriptions, Icon, PageHeader, Row } from 'antd'
import Loader from '@antegma/accallio-cloud-react-components/lib/app/components/Loader'
import { ISagas } from '~/redux/interface'
import { getUser } from '~/redux/users/details/actions'

const UserDetails = (): JSX.Element => {
  const dispatch = useDispatch()
  const route = useRouteMatch<{ userId: string }>()
  const {
    params: { userId },
  } = route

  const userDetailsState = useSelector((state: ISagas) => {
    return state.userDetails
  })

  useEffect(() => {
    dispatch(getUser(userId))
  }, [])

  const handleClickUserDetailsEdit = (): void => {
    console.log('Clicked')
  }

  return userDetailsState.loading !== true ? (
    <PageHeader title={`User: ${userDetailsState.user.givenName} ${userDetailsState.user.familyName}`}>
      <Row gutter={16}>
        <Col span={24}>
          <Card type="inner" title="User details" extra={<Icon type="edit" onClick={handleClickUserDetailsEdit} />}>
            <Descriptions column={1}>
              <Descriptions.Item label="First name">{userDetailsState.user.givenName}</Descriptions.Item>
              <Descriptions.Item label="Last name">{userDetailsState.user.familyName}</Descriptions.Item>
              <Descriptions.Item label="Email">{userDetailsState.user.email}</Descriptions.Item>
              <Descriptions.Item label="Preferred Language">{userDetailsState.user.preferredLanguage}</Descriptions.Item>
              <Descriptions.Item label="Invitation Pending">
                {userDetailsState.user.invitationPending ? <Icon type="check" /> : null}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      </Row>
    </PageHeader>
  ) : (
    <Loader />
  )
}

export default {
  name: 'User Details',
  routeProps: {
    path: '/:userId',
    component: UserDetails,
  },
  navProps: {
    title: 'User Details',
  },
}
