import React, { useState } from 'react'
import { Button, Card } from 'antd'
import { PlusCircleTwoTone } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { ISagas } from '~/redux/interface'
import UserTable from './UserTable'
import UserAddModal from './UserAddModal'


type UserTabProps = {
  tenantId: string
}

const UserTab = ({ tenantId }: UserTabProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const tenantDetailsState = useSelector((state: ISagas) => {
    return state.tenantDetails
  })

  return (
    <>
      <Card
        title="Users"
        extra={
          <Button icon={<PlusCircleTwoTone />} onClick={() => setIsModalOpen(true)}>
            Add User
          </Button>
        }
      >
        <UserTable usersState={{ users: tenantDetailsState.users, page: 1, total: tenantDetailsState.users.length, pageSize: 10 }} />
      </Card>
      <UserAddModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} tenantId={tenantId} />
    </>

  )
}

export default UserTab;