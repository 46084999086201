/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { all, put, takeEvery, select } from 'redux-saga/effects'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/app/redux/interface'
import { AnyAction } from 'redux'
import { notification } from 'antd'
import { User } from '@antegma/accallio-cloud-management-api'
import userApi from '~/services/management/userApi'
import actions from './actions'

/**
 *
 */
export function* GET_USERS({ payload }: AnyAction) {
    const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
    const options = { headers: { Authorization: `Bearer ${accessToken}` } }

    const { page, pageSize } = payload

    const response = yield userApi.getUsers(page, pageSize, options).catch((error) => {
        notification.error({
            message: 'User: cannot load Users',
            description: error.message,
        })
    })

    yield put({
        type: actions.SET_STATE,
        payload: {
            users: response.data.items,
            page: response.data.page,
            pageSize: response.data.pageSize,
            total: response.data.total,
        },
    })
}

/**
 *
 */
export function* ADD_USER({ payload }: AnyAction) {
    const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
    const options = { headers: { Authorization: `Bearer ${accessToken}` } }

    const { user } = payload

    yield userApi.createUser(user, options)

    yield put({
        type: actions.GET_USERS,
    })
}

/**
 *
 */
export function* EDIT_USER({ payload }: AnyAction) {
    const { user } = payload
    const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
    const options = { headers: { Authorization: `Bearer ${accessToken}` } }

    const userId = (user as User).id as string

    yield userApi.updateUser(userId, user, options)

    yield put({
        type: actions.GET_USERS,
    })
}

/**
 *
 */
export function* DELETE_USER({ payload }: AnyAction) {
    const { userId } = payload
    const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
    const options = { headers: { Authorization: `Bearer ${accessToken}` } }

    yield userApi.deleteUser(userId, options).catch((error) => {
        notification.error({
            message: `Users: Unable to delete User with ID ${userId}`,
            description: error.message,
        })
    })
    yield put({
        type: actions.GET_USERS,
    })
}

export function* SEARCH_USER({ payload }: AnyAction) {
    const { value } = payload
    const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
    const options = { headers: { Authorization: `Bearer ${accessToken}` } }

    const response = yield userApi.getUserByEmail(value, options).catch((error) => {
        notification.error({
            message: `Users: Unable to find User with search value ${value}`,
            description: error.message,
        })
    })

    yield put({
        type: actions.SET_STATE,
        payload: {
            searchResult: [response.data],
        },
    })
}

/**
 *
 */
export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_USERS, GET_USERS),
        takeEvery(actions.DELETE_USER, DELETE_USER),
        takeEvery(actions.ADD_USER, ADD_USER),
        takeEvery(actions.EDIT_USER, EDIT_USER),
        takeEvery(actions.SEARCH_USER, SEARCH_USER),
    ])
}
