/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { Descriptions, PageHeader, Row, Col, Card, Button, Tabs } from 'antd'
import { EditTwoTone } from '@ant-design/icons';
import Loader from '@antegma/accallio-cloud-react-components/lib/app/components/Loader'
import UserTab from './components/UserTab';
import { ISagas } from '~/redux/interface'
import { getTenant, getTenantUsers } from '~/redux/tenants/details/actions'
import ProductInstanceTab from './components/ProductInstanceTab';
import SubscriptionsTab from './components/SubscriptionsTab';



const TenantDetails = (): JSX.Element => {
    const dispatch = useDispatch();

    const route = useRouteMatch<{ tenantId: string }>()
    const {
        params: { tenantId },
    } = route

    const tenantDetailsState = useSelector((state: ISagas) => {
        return state.tenantDetails
    })

    useEffect(() => {
        dispatch(getTenant(tenantId));
        dispatch(getTenantUsers(tenantId));
    }, [])


    return tenantDetailsState.loading !== true ? (
        <PageHeader title={`Tenant: ${tenantDetailsState.tenant.name}`}>
            <Row gutter={16}>
                <Col span={24}>
                    <Card
                        title="Tenant details"
                        extra={
                            <Button icon={<EditTwoTone />}>
                                Edit
                            </Button>
                        }
                    >
                        <Descriptions column={1}>
                            <Descriptions.Item label="Name">{tenantDetailsState.tenant.name}</Descriptions.Item>
                            <Descriptions.Item label="State">{tenantDetailsState.tenant.state}</Descriptions.Item>
                            <Descriptions.Item label="Code">{tenantDetailsState.tenant.code}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Tabs defaultActiveKey="1">
                        <Tabs.TabPane tab="Users" key="1">
                            <UserTab tenantId={tenantId} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Product Instances" key="2">
                            <ProductInstanceTab tenantId={tenantId} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Subscriptions" key="3">
                            <SubscriptionsTab tenantId={tenantId} />
                        </Tabs.TabPane>
                    </Tabs>
                </Col>
            </Row>
        </PageHeader>
    ) : (
        <Loader />
    )
}

export default {
    name: 'Tenant Details',
    routeProps: {
        path: '/:tenantId',
        component: TenantDetails,
    },
    navProps: {
        title: 'Tenant Details',
    },
}
