import { Col, Form, Input, Modal, Row } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { inviteUser } from '~/redux/tenants/details/actions';

type UserAddModalProps = {
    isModalOpen: boolean;
    setIsModalOpen: (isModalOpen: boolean) => void;
    tenantId: string;
}

const UserAddModal = ({ isModalOpen, setIsModalOpen, tenantId }: UserAddModalProps): JSX.Element => {
    const dispatch = useDispatch();
    const [givenName, setGivenName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [email, setEmail] = useState('');

    const handleOk = () => {
        dispatch(inviteUser(tenantId, { "givenName": givenName, "familyName": familyName, "email": email }));
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const isFormValid = givenName && familyName && email;

    return (
        <Modal
            title="Add User"
            visible={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okButtonProps={{ disabled: !isFormValid }}
        >
            <Form layout="vertical">
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="Given Name">
                            <Input
                                required
                                placeholder="Given Name"
                                value={givenName}
                                onChange={(e) => setGivenName(e.target.value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Family Name">
                            <Input
                                required
                                placeholder="Family Name"
                                value={familyName}
                                onChange={(e) => setFamilyName(e.target.value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="E-Mail">
                            <Input
                                required
                                placeholder="E-Mail"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default UserAddModal;