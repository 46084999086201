/* eslint-disable no-async-promise-executor */
import { ProductInstanceApi } from '@antegma/accallio-cloud-management-api'
import { getEnv } from '@antegma/accallio-cloud-react-components/lib/app/util/env'
import { Api } from '@antegma/accallio-cloud-react-components/lib/service/api'

type TEnv = {
    MANAGEMENT_API_BASE_PATH: string
}

const env = getEnv<TEnv>({
    MANAGEMENT_API_BASE_PATH: process.env.REACT_APP_MANAGEMENT_API_BASE_PATH || 'https://management.accallio.com',
})

const productInstanceApi = new ProductInstanceApi(undefined, env.MANAGEMENT_API_BASE_PATH, Api.instance.axiosInstance)

export default productInstanceApi
