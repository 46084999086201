import React from 'react'
import { Helmet } from 'react-helmet'
import { PageHeader } from 'antd'
import Tenants from './tenants'
import Users from './users'
import Products from './products'

const Settings = (): JSX.Element => {
    return (
        <>
            <Helmet title="Manage" />
            <PageHeader title="Manage">Placeholder</PageHeader>
        </>
    )
}

export default {
    name: 'Manage',
    routeProps: {
        path: '/manage',
        component: Settings,
    },
    children: [Tenants, Users, Products],
    navProps: {
        title: 'Manage',
        icon: 'Manage',
    },
}
