import { Tenant, User } from '@antegma/accallio-cloud-management-api'
import { AnyAction } from 'redux'
import actions from './actions'

export type TTenantDetailsState = {
  tenant: Tenant
  users: User[]
  loading: boolean
}

const initialState = {
  tenant: {},
  users: [],
  loading: false,
}

/**
 * @param state
 * @param action
 */
export default function tenantsReducer(state: TTenantDetailsState = initialState, action: AnyAction): TTenantDetailsState {
  switch (action.type) {
    case actions.SET_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case actions.GET_STATE:
      return state
    default:
      return state
  }
}
