import React from 'react'
import { PageHeader, Pagination, Table, Popconfirm, Tooltip, Button } from 'antd'
import { ProductInstanceMember } from '@antegma/accallio-cloud-management-api'
import { DeleteOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { deleteProductInstanceMember } from '~/redux/product-instance-member/actions'


type MemberTableProps = {
    productInstanceMemberState: {
        productInstanceMembers: ProductInstanceMember[]
        page: number
        total: number
        pageSize: number
    },
}

const MemberTable = ({ productInstanceMemberState }: MemberTableProps): JSX.Element => {
    const dispatch = useDispatch()
    const columns = [
        {
            title: 'E-Mail',
            dataIndex: 'user',
            key: 'user',
            render: (user: any): string => `${user.email}`,
        },
        {
            title: 'Name',
            dataIndex: 'user',
            key: 'name',
            sorter: true,
            render: (user: any): string => `${user.givenName} ${user.familyName}`,

        },
        {
            title: 'Action',
            key: 'action',
            render: (item: any): JSX.Element => {
                return (
                    <Popconfirm
                        cancelText="No"
                        okText="Yes"
                        onConfirm={() => dispatch(deleteProductInstanceMember(item.id))}
                        title="Are you sure you want to remove this member?"
                    >
                        <Tooltip placement="left" title="Remove Member">
                            <Button type="link">
                                <DeleteOutlined />
                            </Button>
                        </Tooltip>
                    </Popconfirm>
                )
            },
        },
    ]

    return (
        <PageHeader>
            <Table columns={columns} dataSource={productInstanceMemberState.productInstanceMembers} pagination={false} />
            <Pagination defaultCurrent={productInstanceMemberState.page} total={productInstanceMemberState.total} pageSize={productInstanceMemberState.pageSize} />
        </PageHeader>
    )
}

export default MemberTable;