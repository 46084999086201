import { AnyAction } from 'redux'

const actions = {
  SET_STATE: 'USERS/SET_STATE',
  GET_STATE: 'USERS/GET_STATE',
  GET_USER: 'USERS/GET_USER',
}
export default actions

export const getUser = (userId: string): AnyAction => {
  return {
    type: actions.GET_USER,
    payload: {
      userId,
    },
  }
}
