/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { all, put, takeEvery, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/app/redux/interface'
import { AnyAction } from 'redux'
import productApi from '~/services/management/productApi'
import actions from './actions'



export function* GET_PRODUCTS({ payload }: AnyAction) {
    const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }));

    yield put({
        type: actions.SET_STATE,
        payload: {
            loading: true,
        },
    })

    const options = { headers: { Authorization: `Bearer ${accessToken}` } };
    const { page, pageSize } = payload;

    const response = yield productApi.getProducts(page, pageSize, options).catch((error) => {
        notification.error({
            message: 'Products: cannot load Products',
            description: error.message,
        })
    })

    yield put({
        type: actions.SET_STATE,
        payload: {
            loading: false,
            products: response.data.items,
            page: response.data.page,
            total: response.data.total,
        },
    })
}

export function* ADD_PRODUCT({ payload }: AnyAction) {
    const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
    const options = { headers: { Authorization: `Bearer ${accessToken}` } }

    const { product } = payload
    yield productApi.createProduct(product, options);

    yield put({
        type: actions.GET_PRODUCTS,
        payload: {
            loading: true,
        },
    })
}

/**
 *
 */
export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_PRODUCTS, GET_PRODUCTS),
        takeEvery(actions.ADD_PRODUCT, ADD_PRODUCT),
    ])
}
