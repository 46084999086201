import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card } from 'antd'
import { ISagas } from '~/redux/interface'
import { getSubscriptions } from '~/redux/subscriptions/actions'
import SubscriptionsTable from './SubscriptionsTable'


type SubscriptionsTabProps = {
    tenantId: string
}

const SubscriptionsTab = ({ tenantId }: SubscriptionsTabProps): JSX.Element => {
    const dispatch = useDispatch();
    const subscriptionsState = useSelector((state: ISagas) => { return state.subscriptions });

    useEffect(() => {
        dispatch(getSubscriptions(subscriptionsState.page, subscriptionsState.pageSize, tenantId));
    }, [subscriptionsState.page, subscriptionsState.pageSize])

    return (
        <>
            <Card title="Subscriptions">
                <SubscriptionsTable subscriptionsState={subscriptionsState} />
            </Card>
        </>
    )
}

export default SubscriptionsTab;