/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Icon, PageHeader, Pagination, Popconfirm, Table, Tooltip } from 'antd'
import Loader from '@antegma/accallio-cloud-react-components/lib/app/components/Loader'
import { Tenant } from '@antegma/accallio-cloud-management-api'
import { ISagas } from '~/redux/interface'
import { getTenants, deleteTenant } from '~/redux/tenants/actions'
import TenantDetails from './details'

const Tenants = (): JSX.Element => {
    const dispatch = useDispatch()

    const tenantsState = useSelector((state: ISagas) => {
        return state.tenants
    })

    const handleDeleteTenant = (tenant: Tenant): void => {
        if (tenant.id !== undefined) {
            dispatch(deleteTenant(tenant.id))
        }
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: any): JSX.Element => <Link to={`tenants/${record.id}`}>{text}</Link>,
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            render: (text: string, record: any): string => record.state,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_text, record: Tenant): JSX.Element => {
                return (
                    <>
                        <Popconfirm
                            cancelText="No"
                            okText="Yes"
                            onConfirm={(): void => {
                                handleDeleteTenant(record)
                            }}
                            title="Are you sure delete this tenant?"
                        >
                            <Tooltip placement="left" title="Delete Tenant">
                                <Button type="link">
                                    <Icon type="delete" />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </>
                )
            },
        },
    ]

    useEffect(() => {
        dispatch(getTenants(tenantsState.page, tenantsState.pageSize))
    }, [])

    const onChange = (page: number, pageSize?: number): void => {
        dispatch(getTenants(page, pageSize !== undefined ? pageSize : tenantsState.pageSize))
    }

    return tenantsState.loading !== true ? (
        <PageHeader title="Tenants">
            <Table columns={columns} dataSource={tenantsState.tenants} pagination={false} />
            <Pagination defaultCurrent={tenantsState.page} total={tenantsState.total} pageSize={tenantsState.pageSize} onChange={onChange} />
        </PageHeader>
    ) : (
        <Loader />
    )
}

export default {
    name: 'Tenants',
    routeProps: {
        path: '/tenants',
        component: Tenants,
    },
    navProps: {
        title: 'Tenants',
    },
    children: [TenantDetails],
}
