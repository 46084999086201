import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card } from 'antd'
import { PlusCircleTwoTone } from '@ant-design/icons'
import ProductInstanceAddModal from './ProductInstanceAddModal'
import ProductInstanceTable from './ProductInstanceTable'
import { ISagas } from '~/redux/interface'
import { getProductInstances } from '~/redux/product-instance/actions'
import ProductInstanceMemberModal from './ProductInstanceMemberModal'


type ProductInstanceTabProps = {
    tenantId: string
}

const ProductInstanceTab = ({ tenantId }: ProductInstanceTabProps): JSX.Element => {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMemberModalOpen, setIsMemberModalOpen] = useState(false);
    const productInstancesState = useSelector((state: ISagas) => { return state.productInstances });

    useEffect(() => {
        dispatch(getProductInstances(productInstancesState.page, productInstancesState.pageSize, tenantId));
    }, [productInstancesState.page, productInstancesState.pageSize])

    return (
        <>
            <Card
                title="Product Instances"
                extra={
                    <Button icon={<PlusCircleTwoTone />} onClick={() => setIsModalOpen(true)}>
                        Add Product Instance
                    </Button>
                }
            >
                <ProductInstanceTable productInstancesState={productInstancesState} openMemberModal={setIsMemberModalOpen} />
            </Card>
            <ProductInstanceAddModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} tenantId={tenantId} />
            {isMemberModalOpen &&
                <ProductInstanceMemberModal isModalOpen={isMemberModalOpen} setIsModalOpen={setIsMemberModalOpen} tenantId={tenantId} />
            }
        </>
    )
}

export default ProductInstanceTab;