import { Col, Form, Modal, Row } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddUserInput from './AddUserInput';
import MemberTable from './MemberTable';
import { ISagas } from '~/redux/interface';
import { getProductInstanceMembers, reset } from '~/redux/product-instance-member/actions';

type UserAddModalProps = {
    isModalOpen: boolean;
    setIsModalOpen: (isModalOpen: boolean) => void;
    tenantId: string;
}

const ProductInstanceMemberModal = ({ isModalOpen, setIsModalOpen, tenantId }: UserAddModalProps): JSX.Element => {
    const dispatch = useDispatch();
    const productInstanceMemberState = useSelector((state: ISagas) => { return state.productInstanceMembers });
    const { selectedProductInstanceId } = useSelector((state: ISagas) => { return state.productInstances });

    useEffect(() => {
        if (productInstanceMemberState.fetchStatus === 'idle')
            dispatch(getProductInstanceMembers(productInstanceMemberState.page, productInstanceMemberState.pageSize, tenantId, selectedProductInstanceId, ''));
    }, [productInstanceMemberState.fetchStatus, productInstanceMemberState.page, productInstanceMemberState.pageSize]);

    const handleOk = () => {
        handleCancel();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        dispatch(reset());
    };

    return (
        <Modal
            title="Add a Member"
            visible={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            width={1000}
        >
            <Form layout="vertical">
                <Row gutter={24}>
                    <Col span={24}>
                        <AddUserInput tenantId={tenantId} />
                    </Col>
                </Row>
            </Form>
            <MemberTable productInstanceMemberState={productInstanceMemberState} />
        </Modal>
    );
};

export default ProductInstanceMemberModal;