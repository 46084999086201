import { Button, Row, Select } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ISagas } from "~/redux/interface";
import { addProuctInstanceMember } from "~/redux/product-instance-member/actions";
import { searchUser } from "~/redux/users/actions";

type AddUserInputProps = {
    tenantId: string;
}

const AddUserInput = ({ tenantId }: AddUserInputProps) => {
    const dispatch = useDispatch();
    const [value, setValue] = useState<string>();
    const [isSelected, setisSelected] = useState<boolean>(false);
    const { searchResult } = useSelector((state: ISagas) => { return state.users });
    let timeout: ReturnType<typeof setTimeout> | null;
    const { selectedProductInstanceId } = useSelector((state: ISagas) => { return state.productInstances });

    const fetch = (searchValue: string) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }

        const fetchUsers = () => {
            dispatch(searchUser(searchValue));
        };
        timeout = setTimeout(fetchUsers, 500);
    };

    const handleSearch = (newValue: string) => {
        if (newValue) {
            fetch(newValue);
        }
    };

    const handleChange = (newValue: string) => {
        setValue(newValue);
    };

    const assignUser = () => {
        setValue("");
        setisSelected(false);
        const productInstanceMember = {
            tenant: { id: tenantId },
            productInstance: { id: selectedProductInstanceId },
            user: searchResult[0],
            status: "ACTIVE",
        };
        dispatch(addProuctInstanceMember(productInstanceMember));
    };

    return (
        <Row gutter={24} >
            <Select
                showSearch
                value={value}
                placeholder="Search for a user"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearch}
                onChange={handleChange}
                onSelect={() => setisSelected(true)}
                notFoundContent={null}
                style={{ marginRight: '16px', width: '300px' }}
                options={(searchResult || []).map(d => ({
                    value: d.id,
                    label: d.email,
                }))}
            />
            <Button
                type="primary"
                disabled={!isSelected}
                onClick={assignUser}
            >Add</Button>
        </Row>
    )
}
export default AddUserInput;