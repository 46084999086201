import { AnyAction } from 'redux'

const actions = {
    GET_SUBSCRIPTIONS: 'SUBSCRIPTIONS/GET_SUBSCRIPTIONS',
    CHANGE_PAGINATION: 'PRODUCT/CHANGE_PAGINATION',
    GET_STATE: 'PRODUCT/GET_STATE',
    SET_STATE: 'PRODUCT/SET_STATE',

}
export default actions

export const getSubscriptions = (page: number, pageSize: number, tenantId: string) => {
    return {
        type: actions.GET_SUBSCRIPTIONS,
        payload: {
            page,
            pageSize,
            tenantId,
        },
    }
}

export const changePagination = (page: number, pageSize: number): AnyAction => {
    return {
        type: actions.CHANGE_PAGINATION,
        payload: {
            page,
            pageSize
        },
    }
}
