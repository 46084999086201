import React from 'react'
import { Link } from 'react-router-dom'
import { PageHeader, Pagination, Table, Icon, Popconfirm, Tooltip, Button } from 'antd'
import { Product } from '@antegma/accallio-cloud-management-api'
import { useDispatch } from 'react-redux'
import { changePagination } from '~/redux/products/actions'

type ProductTableProps = {
  productState: {
    products: Product[]
    page: number
    total: number
    pageSize: number
  }
}

const ProductTable = ({ productState }: ProductTableProps): JSX.Element => {
  const dispatch = useDispatch()
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (text: string, record: Product): JSX.Element => (
        <Link to={`products/${record.id}`}>
          {text}
        </Link>
      ),
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      render: (text: any): string => `${text}`,
    },
    {
      title: 'Database Prefix',
      dataIndex: 'databasePrefix',
      key: 'databasePrefix',
      sorter: true,
      render: (text: any): string => `${text}`,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_text: any, record: Product): JSX.Element => {
        return (
          <>
            <Popconfirm
              cancelText="No"
              okText="Yes"
              onConfirm={() => console.log('delete product')}
              title="Are you sure delete this product?"
            >
              <Tooltip placement="left" title="Delete product">
                <Button type="link">
                  <Icon type="delete" />
                </Button>
              </Tooltip>
            </Popconfirm>
          </>
        )
      },
    },
  ]

  const handlepagination = (page: number, pageSize: number) => {
    dispatch(changePagination(page, pageSize));
  }

  return (
    <PageHeader>
      <Table columns={columns} dataSource={productState.products} pagination={false} />
      <Pagination defaultCurrent={productState.page} total={productState.total} pageSize={productState.pageSize} onChange={(page, pageSize) => handlepagination(page, pageSize)} />
    </PageHeader>
  )
}

export default ProductTable;