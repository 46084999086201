import { AnyAction } from 'redux'

const actions = {
  SET_STATE: 'TENANTS/SET_STATE',
  GET_STATE: 'TENANTS/GET_STATE',
  GET_TENANT: 'TENANTS/GET_TENANT',
  GET_TENANT_USERS: 'TENANTS/GET_TENANT_USERS',
  INVITE_USER: 'TENANTS/INVITE_USER',
}
export default actions

export const getTenant = (tenantId: string): AnyAction => {
  return {
    type: actions.GET_TENANT,
    payload: {
      tenantId,
    },
  }
}

export const getTenantUsers = (tenantId: string): AnyAction => {
  return {
    type: actions.GET_TENANT_USERS,
    payload: {
      tenantId,
    },
  }
}

export const inviteUser = (tenantId: string, user: any): AnyAction => {
  return {
    type: actions.INVITE_USER,
    payload: {
      tenantId,
      user,
    },
  }
}
