import { Col, Form, Input, InputNumber, Modal, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ISagas } from '~/redux/interface';
import { createProuctInstance } from '~/redux/product-instance/actions';
import { getProducts } from '~/redux/products/actions';

type UserAddModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  tenantId: string;
}

const ProductInstanceAddModal = ({ isModalOpen, setIsModalOpen, tenantId }: UserAddModalProps): JSX.Element => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [productId, setProductId] = useState('');
  const [licenseQuota, setLicenseQuota] = useState('');
  const { products } = useSelector((state: ISagas) => { return state.products });
  const productOptions = products.map(item => ({
    value: item.id,
    label: item.name
  }));

  useEffect(() => {
    if (isModalOpen)
      dispatch(getProducts(1, 999));
  }, [isModalOpen]);

  const handleOk = () => {
    const product = { id: productId };
    const tenant = { id: tenantId };
    dispatch(createProuctInstance({ name, code, product, tenant, licenseQuota: parseInt(licenseQuota, 10) }));
    handleCancel();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const isFormValid = name && code && productId;

  return (
    <Modal
      title="Add a new Product Instance"
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ disabled: !isFormValid }}
    >
      <Form layout="vertical">
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="Name">
              <Input
                required
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Code">
              <Input
                required
                placeholder="Code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Product">
              <Select
                onChange={(value) => setProductId(value)}
                options={productOptions}
                placeholder="Select a product"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="License Quota">
              <InputNumber
                style={{ width: '100%' }}
                required
                placeholder="License Quota"
                value={licenseQuota}
                onChange={(value) => setLicenseQuota(value)}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ProductInstanceAddModal;