import { Product } from '@antegma/accallio-cloud-management-api'
import { AnyAction } from 'redux'

const actions = {
    GET_PRODUCTS: 'PRODUCT/GET_PRODUCTS',
    ADD_PRODUCT: 'PRODUCT/ADD_PRODUCT',
    CHANGE_PAGINATION: 'PRODUCT/CHANGE_PAGINATION',
    GET_STATE: 'PRODUCT/GET_STATE',
    SET_STATE: 'PRODUCT/SET_STATE',

}
export default actions

export const getProducts = (page: number, pageSize: number) => {
    return {
        type: actions.GET_PRODUCTS,
        payload: {
            page,
            pageSize,
        },
    }
}

export const createProuct = (product: Product): AnyAction => {
    return {
        type: actions.ADD_PRODUCT,
        payload: {
            product,
        },
    }
}

export const changePagination = (page: number, pageSize: number): AnyAction => {
    return {
        type: actions.CHANGE_PAGINATION,
        payload: {
            page,
            pageSize
        },
    }
}
