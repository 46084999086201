import { AnyAction } from 'redux'

const actions = {
  SET_STATE: 'TENANTS/SET_STATE',
  GET_STATE: 'TENANTS/GET_STATE',
  GET_TENANTS: 'TENANTS/GET_TENANTS',
  DELETE_TENANT: 'TENANTS/DELETE_TENANT',
  ADD_TENANT: 'TENANTS/ADD_TENANT',
  EDIT_TENANT: 'TENANTS/EDIT_TENANT',
}
export default actions

export const getTenants = (page: number, pageSize: number): AnyAction => {
  return {
    type: actions.GET_TENANTS,
    payload: {
      page,
      pageSize,
    },
  }
}

export const deleteTenant = (tenantId: string): AnyAction => {
  return {
    type: actions.DELETE_TENANT,
    payload: {
      tenantId,
    },
  }
}
