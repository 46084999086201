import React from 'react'
import { Link } from 'react-router-dom'
import { PageHeader, Pagination, Table, Popconfirm, Tooltip, Button } from 'antd'
import { ProductInstance } from '@antegma/accallio-cloud-management-api'
import { DeleteOutlined, UserAddOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { changeSelectedProductInstanceId, deleteProductInstance } from '~/redux/product-instance/actions'


type ProductInstanceTableProps = {
  productInstancesState: {
    productInstances: ProductInstance[]
    page: number
    total: number
    pageSize: number
  },
  openMemberModal: (isModalOpen: boolean) => void;
}

const ProductInstanceTable = ({ productInstancesState, openMemberModal }: ProductInstanceTableProps): JSX.Element => {
  const dispatch = useDispatch()
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (text: string, item: any): JSX.Element => (
        <Link to={`product-instance/${item.id}`}>
          {text}
        </Link>
      ),
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      render: (text: any): string => `${text}`,
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      render: (product: any): string => `${product.name}`,
    },
    {
      title: 'Action',
      key: 'action',
      render: (item: any): JSX.Element => {
        return (
          <>
            <Popconfirm
              cancelText="No"
              okText="Yes"
              onConfirm={() => dispatch(deleteProductInstance(item.id))}
              title="Are you sure you want to delete this instance?"
            >
              <Tooltip placement="left" title="Delete Instance">
                <Button type="link">
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>
            <Tooltip placement="left" title="Add Member">
              <Button type="link" onClick={() => {
                openMemberModal(true);
                dispatch(changeSelectedProductInstanceId(item.id));
              }}>
                <UserAddOutlined />
              </Button>
            </Tooltip>

          </>
        )
      },
    },
  ]

  return (
    <PageHeader>
      <Table columns={columns} dataSource={productInstancesState.productInstances} pagination={false} />
      <Pagination defaultCurrent={productInstancesState.page} total={productInstancesState.total} pageSize={productInstancesState.pageSize} />
    </PageHeader>
  )
}

export default ProductInstanceTable;