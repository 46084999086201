import { User } from '@antegma/accallio-cloud-management-api'
import { AnyAction } from 'redux'
import actions from './actions'

export type TUsersState = {
    users: User[]
    searchResult: User[]
    page: number
    pageSize: number
    total: number
    loading: boolean
}

const initialState = {
    users: [],
    searchResult: [],
    page: 1,
    pageSize: 10,
    total: 0,
    loading: false,
}

/**
 * @param state
 * @param action
 */
export default function usersReducer(state: TUsersState = initialState, action: AnyAction): TUsersState {
    switch (action.type) {
        case actions.SET_STATE:
            return {
                ...state,
                ...action.payload,
            }
        case actions.GET_STATE:
            return state
        default:
            return state
    }
}
