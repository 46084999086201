import { ProductInstanceMember } from '@antegma/accallio-cloud-management-api'
import { AnyAction } from 'redux'
import actions from './actions'

export type TProductInstanceMemberState = {
    productInstanceMembers: ProductInstanceMember[]
    fetchStatus: string
    page: number
    pageSize: number
    total: number
    loading: boolean
}

const initialState = {
    productInstanceMembers: [],
    fetchStatus: 'idle',
    page: 1,
    pageSize: 10,
    total: 0,
    loading: false,
}

/**
 * @param state
 * @param action
 */
export default function productInstanceMemberReducer(state: TProductInstanceMemberState = initialState, action: AnyAction): TProductInstanceMemberState {
    switch (action.type) {
        case actions.SET_STATE:
            return {
                ...state,
                ...action.payload,
            }
        case actions.GET_STATE:
            return state
        case actions.CHANGE_PAGINATION:
            return {
                ...state,
                page: action.payload.page,
                pageSize: action.payload.pageSize,
            };
        case actions.RESET:
            return {
                ...initialState,
            };
        default:
            return state
    }
}
